
import { defineComponent, ComputedRef, computed, ref, onMounted, SetupContext } from 'vue'
import useMembersOverlay from '@/composables/useMembersOverlay'
import useMessages from '@/composables/useMessages'
import constants from '@/util/constants'
import { Message } from '@/types/message'
import { MemberId } from '@/types/member'

export default defineComponent({
  name: 'ChatMessagesItem',
  components: {
  },
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  emits: ['mounted'],
  setup(props: any, context: SetupContext) {
    const { removeMessage, getMessage, sortMessages } = useMessages()
    const { openMembersOverlay } = useMembersOverlay()
    const messageRef: ComputedRef<Message> = computed(() => props.message)
    const isVisibleRef = ref(false)
    const removeMessageRef = ref(false)
    const isDragOverRef = ref(false)
    const isDraggedRef = ref(false)
    const maxLength = 1000

    onMounted(() => {
      setTimeout(() => {
        isVisibleRef.value = true
        context.emit('mounted')
      }, 100)
    })

    const handleRemoveMessage = () => {
      if (!removeMessageRef.value) {
        removeMessageRef.value = true
        return
      }
      isVisibleRef.value = false
      setTimeout(() => {
        removeMessage(messageRef.value.id)
      }, 500)
    }

    const handleRemoveMouseLeave = () => {
      removeMessageRef.value = false
    }

    const chooseMemberCallback = (memberId: MemberId) => {
      messageRef.value.memberId = memberId
    }

    const handleChooseMember = () => {
      openMembersOverlay(chooseMemberCallback)
    }

    const handleTextBlur = (event: any) => {
      const text: string = event.target.innerText
      if (text.length > maxLength) {
        event.target.innerText = text.substring(0, maxLength)
        return
      }
      messageRef.value.text = text
    }

    const startDrag = (event: DragEvent) => {
      if (!event.dataTransfer) {
        return
      }
      setTimeout(() => {
        isDraggedRef.value = true
      }, 100)
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      const element: HTMLElement = event.target as HTMLElement
      event.dataTransfer.setData('messageId', messageRef.value.id.toString())
      event.dataTransfer.setData('elementHeight', element.offsetHeight.toString())
    }

    const onDrop = (event: DragEvent) => {
      isDragOverRef.value = false
      if (!event.dataTransfer) {
        return
      }
      const messageId = parseInt(event.dataTransfer.getData('messageId'))
      if (!messageId) {
        return
      }
      const sourceMessage: Message | undefined = getMessage(messageId)
      if (sourceMessage) {
        sortMessages(sourceMessage, messageRef.value)
      }
    }

    const defaultMemberImage = constants.defaultMemberImage

    return {
      messageRef,
      isVisibleRef,
      handleRemoveMessage,
      handleChooseMember,
      handleTextBlur,
      startDrag,
      onDrop,
      isDragOverRef,
      isDraggedRef,
      removeMessageRef,
      handleRemoveMouseLeave,
      defaultMemberImage
    }
  }
})
