
import { defineComponent, ref, computed } from 'vue'
import useMembersOverlay from '@/composables/useMembersOverlay'
import useMembers from '@/composables/useMembers'
import useMessages from '@/composables/useMessages'
import ChatMembersItem from '@/components/ChatMembersItem.vue'
import ChatMembersInput from '@/components/ChatMembersInput.vue'
import { scrollToBottom } from '@/util/utils'

export default defineComponent({
  name: 'ChatMembers',
  components: {
    ChatMembersItem,
    ChatMembersInput
  },
  setup() {
    const { isOpenRef, closeMembersOverlay, chooseCallbackRef } = useMembersOverlay()
    const { membersRef } = useMembers()
    const { messagesRef } = useMessages()
    const membersContainerRef = ref()
    const memberNameRef = ref('')

    const handleCloseClick = () => {
      closeMembersOverlay()
    }

    const handleCreateMember = () => {
      setTimeout(() => {
        scrollToBottom(membersContainerRef.value)
      }, 100)
    }

    const handleEditClick = () => {
      chooseCallbackRef.value = undefined
    }

    document.addEventListener('keyup', (event) => {
      if (isOpenRef.value && event.keyCode === 27) {
        closeMembersOverlay()
      }
    })

    const membersListRef = computed(() => {
      return membersRef.value.map((member) => {
        const isDelatable = !messagesRef.value.some((message) => message.memberId === member.id)
        return {
          ...member,
          isDelatable
        }
      })
    })

    return {
      membersListRef,
      memberNameRef,
      handleCloseClick,
      membersContainerRef,
      handleCreateMember,
      isOpenRef,
      chooseCallbackRef,
      handleEditClick
    }
  }
})
