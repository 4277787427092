
import { defineComponent, SetupContext, ref } from 'vue'
import useMembers from '@/composables/useMembers'
import ImageUploadWrapper from '@/components/ImageUploadWrapper.vue'
import constants from '@/util/constants'

export default defineComponent({
  name: 'ChatMembersInput',
  components: {
    ImageUploadWrapper
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  emits: ['create'],
  setup(props: any, context: SetupContext) {
    const { membersRef, createMember } = useMembers()
    const membersContainerRef = ref()
    const memberNameRef = ref('')
    const isDragRef = ref(false)
    const openFileUploadRef = ref(false)
    const inputElementRef = ref()
    const defaultImageSrc = constants.defaultMemberImage
    const imageSrcRef = ref(defaultImageSrc)
    const handleCreateMember = () => {
      if (!memberNameRef.value.trim()) {
        return
      }
      createMember(memberNameRef.value, imageSrcRef.value)
      memberNameRef.value = ''
      inputElementRef.value.focus()
      imageSrcRef.value = defaultImageSrc
      context.emit('create')
    }

    const handleImageClick = () => {
      openFileUploadRef.value = true
      setTimeout(() => {
        openFileUploadRef.value = false
      }, 500)
    }

    const handleImageLoad = (src: any) => {
      imageSrcRef.value = src
    }

    return {
      membersRef,
      memberNameRef,
      handleCreateMember,
      membersContainerRef,
      isDragRef,
      openFileUploadRef,
      handleImageClick,
      imageSrcRef,
      handleImageLoad,
      inputElementRef
    }
  }
})
