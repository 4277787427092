
import { defineComponent, ref, computed } from 'vue'
import useMembersOverlay from '@/composables/useMembersOverlay'
import useChat from '@/composables/useChat'
import useMembers from '@/composables/useMembers'
import ImageUploadWrapper from '@/components/ImageUploadWrapper.vue'

export default defineComponent({
  name: 'ChatHeader',
  components: {
    ImageUploadWrapper
  },
  setup() {
    const { openMembersOverlay } = useMembersOverlay()
    const { chatSettingsRef, setChatName, setChatImage } = useChat()
    const { membersRef } = useMembers()
    const nameRef = ref(chatSettingsRef.value.name)
    const imageRef = ref(chatSettingsRef.value.image || 'https://via.placeholder.com/40x40')
    const isDragRef = ref(false)
    const openFileUploadRef = ref(false)

    const handleImageLoad = (src: any) => {
      imageRef.value = src
      setChatImage(imageRef.value)
    }

    const handleInput = () => {
      setChatName(nameRef.value)
    }

    const handleImageClick = () => {
      openFileUploadRef.value = true
      setTimeout(() => {
        openFileUploadRef.value = false
      }, 500)
    }

    const handleMembersClick = () => {
      openMembersOverlay()
    }

    const memberNamesRef = computed(() => {
      const totalMaxLength = 35
      const maxNameLength = 10
      let nameCount = 2
      let text = ''
      membersRef.value.forEach((member) => {
        if (member.id <= 1) {
          return
        }
        const name = `${member.name}${member.name.length > maxNameLength ? '...' : ''}`
        if (text.length + name.length > totalMaxLength) {
          return true
        }
        nameCount++
        text = `${text}, ${name}`
      })
      text = text.substring(2)
      const membersLength = membersRef.value.length
      if (membersLength > nameCount) {
        text = `${text} + ${membersLength - nameCount}`
      }
      return text
    })

    return {
      nameRef,
      imageRef,
      handleInput,
      isDragRef,
      handleImageClick,
      openFileUploadRef,
      handleImageLoad,
      handleMembersClick,
      membersRef,
      memberNamesRef
    }
  }
})
