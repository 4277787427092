
import { defineComponent, ref, ComputedRef, computed, onMounted } from 'vue'
import useMembersOverlay from '@/composables/useMembersOverlay'
import useMembers from '@/composables/useMembers'
import ImageUploadWrapper from '@/components/ImageUploadWrapper.vue'
import { Member } from '@/types/member'
import constants from '@/util/constants'

export default defineComponent({
  name: 'ChatMembersItem',
  components: {
    ImageUploadWrapper
  },
  props: {
    member: {
      type: Object,
      required: true
    }
  },
  setup(props: any) {
    const { removeMember, possibleColors, updateMember } = useMembers()
    const { chooseMembersOverlay, chooseCallbackRef, openColorSelect, closeColorSelect, colorSelectIndexRef } = useMembersOverlay()
    const isDragRef = ref(false)
    const isVisibleRef = ref(false)
    const openFileUploadRef = ref(false)
    const isMarkedForRemove = ref(false)
    const colorHoverRef = ref('')
    const memberRef: ComputedRef<Member> = computed(() => props.member)
    const maxLength = 25

    onMounted(() => {
      setTimeout(() => {
        isVisibleRef.value = true
      }, 100)
    })

    const handleRemoveMemberClick = () => {
      if (!memberRef.value.id || !memberRef.value.isDelatable) {
        return
      }
      if (!isMarkedForRemove.value) {
        isMarkedForRemove.value = true
        return
      }
      isVisibleRef.value = false
      setTimeout(() => {
        removeMember(memberRef.value.id)
      }, 500)
    }

    const handleNameInput = (event: any) => {
      const name: string = event.target.innerText
      if (name.length > maxLength) {
        event.target.innerText = name.substring(0, maxLength)
        return
      }
      const member = memberRef.value
      member.name = name
      updateMember(member)
    }

    const handleImageLoad = (image: any) => {
      const member = memberRef.value
      member.image = image
      updateMember(member)
    }

    const handleImageClick = () => {
      if (chooseCallbackRef.value) {
        return
      }
      openFileUploadRef.value = true
      setTimeout(() => {
        openFileUploadRef.value = false
      }, 500)
    }

    const handleChooseMember = () => {
      if (!chooseCallbackRef.value) {
        return
      }
      chooseMembersOverlay(memberRef.value.id)
    }

    const handleColorToggle = () => {
      if (colorSelectIndexRef.value === memberRef.value.id) {
        closeColorSelect()
        return
      }
      openColorSelect(memberRef.value.id)
    }

    const handleColorClick = (color: string) => {
      const member = memberRef.value
      member.color = color
      updateMember(member)
      closeColorSelect()
    }

    const memberColorRef = computed(() => {
      return colorHoverRef.value || memberRef.value.color || 'gray'
    })

    const imageSrcRef = computed(() => memberRef.value.image || constants.defaultMemberImage)

    return {
      handleRemoveMemberClick,
      handleImageLoad,
      handleNameInput,
      isDragRef,
      isVisibleRef,
      imageSrcRef,
      openFileUploadRef,
      handleImageClick,
      chooseMembersOverlay,
      handleChooseMember,
      chooseCallbackRef,
      possibleColors,
      colorSelectIndexRef,
      handleColorToggle,
      colorHoverRef,
      memberColorRef,
      handleColorClick,
      isMarkedForRemove
    }
  }
})
