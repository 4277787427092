
import { defineComponent, ref, computed, watch } from 'vue'
import usePlayer from '@/composables/usePlayer'
import useMessages from '@/composables/useMessages'

export default defineComponent({
  name: 'ChatPlayer',
  setup() {
    const { play, pause, stop, isPlaying, isPausing, playerMessagesRef } = usePlayer()
    const { messagesRef } = useMessages()
    const showProgressBar = ref(false)

    const handlePlayerClick = () => {
      if (isPlaying.value && !isPausing.value) {
        pause()
        return
      }
      showProgressBar.value = true
      play()
    }

    const handlePlayerStop = () => {
      stop()
      showProgressBar.value = false
    }

    const progressStyleRef = computed(() => {
      return {
        minWidth: '10px',
        width: (isPlaying.value ? Math.round(playerMessagesRef.value.length * 100 / messagesRef.value.length) : '100') + '%'
      }
    })

    watch([isPlaying, isPausing], () => {
      if (!isPlaying.value && !isPausing.value) {
        setTimeout(() => {
          if (!isPlaying.value && !isPausing.value) {
            showProgressBar.value = false
          }
        }, 1000)
      }
    })

    return {
      handlePlayerClick,
      handlePlayerStop,
      isPlaying,
      isPausing,
      playerMessagesRef,
      messagesRef,
      progressStyleRef,
      showProgressBar
    }
  }
})
