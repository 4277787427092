
import { defineComponent, SetupContext, ref, Ref } from 'vue'
import ImageUploadWrapper from '@/components/ImageUploadWrapper.vue'
import useMembersOverlay from '@/composables/useMembersOverlay'
import useMessages from '@/composables/useMessages'
import useMembers from '@/composables/useMembers'
import { Member, MemberId } from '@/types/member'
import constants from '@/util/constants'

export default defineComponent({
  name: 'Chat',
  components: {
    ImageUploadWrapper
  },
  emits: ['create'],
  setup(props: any, context: SetupContext) {
    const { openMembersOverlay } = useMembersOverlay()
    const isDragRef = ref(false)
    const openFileUploadRef = ref(false)
    const { createMessage } = useMessages()
    const { membersRef } = useMembers()
    const memberRef: Ref<Member> = ref(membersRef.value[1])
    const inputElementRef = ref()
    const textRef = ref('')
    const imageRef = ref('')

    const handleCreateMessage = () => {
      if (!textRef.value.trim() && !imageRef.value) {
        return
      }
      createMessage(textRef.value, imageRef.value, memberRef.value)
      textRef.value = ''
      imageRef.value = ''
      inputElementRef.value.focus()
      context.emit('create')
    }

    const handleChooseMember = (memberId: MemberId) => {
      const member: Member | undefined = membersRef.value.find((member) => member.id === memberId)
      if (member) {
        memberRef.value = member
      }
    }

    const handleInputMemberClick = () => {
      openMembersOverlay(handleChooseMember)
    }

    const handleKeyPress = (event: any) => {
      if (!event.shiftKey && event.keyCode === 13) {
        event.preventDefault()
        handleCreateMessage()
      }
    }

    const handleImageClick = () => {
      openFileUploadRef.value = true
      setTimeout(() => {
        openFileUploadRef.value = false
      }, 500)
    }

    const handleImageLoad = (src: any) => {
      imageRef.value = src
      handleCreateMessage()
    }

    const defaultMemberImage = constants.defaultMemberImage

    return {
      textRef,
      handleCreateMessage,
      memberRef,
      handleInputMemberClick,
      inputElementRef,
      handleKeyPress,
      isDragRef,
      handleImageClick,
      handleImageLoad,
      openFileUploadRef,
      defaultMemberImage
    }
  }
})
