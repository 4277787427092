
import { defineComponent, ref } from 'vue'
import ChatHeader from '@/components/ChatHeader.vue'
import ChatMessages from '@/components/ChatMessages.vue'
import ChatInput from '@/components/ChatInput.vue'
import ChatMembers from '@/components/ChatMembers.vue'
import ChatNavi from '@/components/ChatNavi.vue'
import ChatPlayer from '@/components/ChatPlayer.vue'
import { scrollToBottom } from '@/util/utils'

export default defineComponent({
  name: 'Chat',
  components: {
    ChatHeader,
    ChatMessages,
    ChatInput,
    ChatMembers,
    ChatNavi,
    ChatPlayer
  },
  setup() {
    const isNavOpen = ref(false)
    const showProgressBar = ref(false)

    const handleCreateMessage = () => {
      setTimeout(() => {
        scrollToBottom()
      }, 100)
    }

    const handleNaviClick = () => {
      isNavOpen.value = !isNavOpen.value
    }

    return {
      handleCreateMessage,
      handleNaviClick,
      isNavOpen,
      showProgressBar
    }
  }
})
