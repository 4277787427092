
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ChatMembers',
  components: {
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
    }
  }
})
